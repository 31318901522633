<template>
  <v-container>
    <v-row style="display:flex;justify-content: space-between;">

      <h3>密钥</h3>
      <v-btn class="mx-2" dark small color="cyan" @click="dialogName='添加密钥';dialog=true;readonly=false">
        <v-icon small dark>mdi-plus</v-icon>添加密钥
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" disable-sort :items="keyList" :loading="loading" hide-default-footer single-select show-select fixed-header v-model="selected"  height='250'>
          <template v-slot:item.created_at="{ item }">
            <span>{{$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}}</span>
          </template>
          <template v-slot:item.type="{ item }">
            <span>{{item.type==='password'?'密码':'密钥'}}</span>
          </template>
          <template v-slot:item.option="{ item }">
            <v-btn class="mx-2" dark small color="primary" @click="dialogName='编辑密钥';edit(item)">
              <v-icon x-small dark>mdi-pencil</v-icon>编辑
            </v-btn>
            <v-btn class="mx-2" dark small color="pink" @click="del(item)">
              <v-icon x-small dark>mdi-delete-outline</v-icon>删除
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{dialogName}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field label="别名" v-model="form.name" :rules="[v => !!v || '不能为空']" required></v-text-field>
                  </v-col>
                  <v-radio-group v-model="radioGroup" row>
                    <v-radio v-for="item in ['密码','私钥']" :key="item" :label="item" :value="item"></v-radio>
                  </v-radio-group>
                  <v-col cols="12" sm="12" md="12" v-if="radioGroup=='密码'">
                    <v-text-field v-model="form.password" label="密码"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-else>
                    <v-textarea outlined name="input-7-4" label="私钥" v-model="form.private_key"></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false;form={}">取消</v-btn>
            <v-btn color="blue darken-1" :disabled="!valid" text @click="save">确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
  import { post, tips } from '@/facade';
  export default {
    data() {
      return {
        loading: true,
        headers: [
          { text: 'ID', value: 'id' },
          { text: '别名', value: 'name' },
          { text: '类型', value: 'type' },
          { text: '密钥', value: 'key' },
          { text: '创建时间', value: 'created_at' },
          { text: '操作', value: 'option' },
        ],
        keyList: [],
        dialogName: '添加密钥',
        dialog: false,
        form: {},
        checkbox: true,
        valid: true,
        radioGroup: '密码',
        selected: [],
      }
    },
    watch: {
      selected(val) {
        this.$emit('keySelect', val)
      }
    },
    async created() {
      await this.getList()
    },
    methods: {
      /**获取密钥列表 */
      async getList() {
        const { code, password_list, message } = await post('/vnet/credential/list')
        if (code === 'ok') {
          this.loading = false;
          this.keyList = password_list
        } else tips('danger', message)
      },
      /**添加修改密钥 */
      async save() {
        const value = this.$refs.form.validate();
        if (value) {
          if (this.dialogName == '添加密钥') {
            const form = this.radioGroup == '密码' ? { key: this.form.password, name: this.form.name, type: 'password' } : { key: this.form.private_key, name: this.form.name, type: 'privateKey' }
            const { code, message } = await post('/vnet/credential/add', form)
            if (code === 'ok') {
              tips('success', '添加成功');
              this.dialog = false;
              this.form = {};
              await this.getList()
            }
            else tips('danger', message)
          } else {
            const form = this.radioGroup == '密码' ? { pid: this.form.pid, type: 'password', key: this.form.password, name: this.form.name } : { pid: this.form.pid, type: 'privateKey', key: this.form.private_key, name: this.form.name }
            const { code, message } = await post('/vnet/credential/update', form)
            if (code === 'ok') {
              tips('success', '编辑成功');
              this.dialog = false;
              this.form = {};
              await this.getList()
            }
            else tips('danger', message)
          }
        }
      },
      /**编辑 */
      edit(item) {
        this.radioGroup = item.type === 'password' ? '密码' : '私钥'
        this.form.username = item.username
        this.form.name = item.name
        this.form.pid = item.id
        this.dialog = true
      },
      /**删除 */
      async del(item) {
        const value = await confirm('即将删除，是否继续')
        if (value) {
          const { code, message } = await post('/vnet/credential/delete', { pid: item.id })
          if (code === 'ok') {
            tips('success', '删除成功');
            await this.getList()
          } else tips('danger', message)
        }
      }
    }

  }
</script>