<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            节点管理({{nodeName}})
            <v-spacer></v-spacer>
            <v-btn color="info" dark small @click="back">返回</v-btn>
          </v-card-title>
          <v-card-subtitle>
          </v-card-subtitle>
          <v-card-text>
            <v-tabs background-color="primary" @change="tabChange()" v-model="currentItem" dark>
              <v-tab v-for="(item,index) in items" :key="index" :href="`#tab-${index}`">{{item.tab}}</v-tab>
              <v-tab-item v-for="(item,index) in items" :key="index" :value="'tab-' + index">
                <v-card flat>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <div>
                      <v-btn class="mx-2" dark small color="warning" @click="detect">
                        探针
                      </v-btn>
                      <v-btn class="mx-2" dark small color="indigo" @click="online">
                        在线人数
                      </v-btn>
                      <v-btn class="mx-2" dark small color="success" @click="install()">
                        安装/卸载
                      </v-btn>
                      <v-btn class="mx-2" dark small color="cyan" @click="dialogName='批量添加节点';dialog=true;editType='add'">
                        添加
                      </v-btn>
                      <v-btn class="mx-2" dark small color="success" @click="exportNode">
                        导出
                      </v-btn>
                      <v-btn class="mx-2" dark small color="teal" @click="statusChange(true)">
                        批量开启
                      </v-btn>
                      <v-btn class="mx-2" dark small color="pink" @click="statusChange(false)">
                        批量关闭
                      </v-btn>
                      <v-btn class="mx-2" dark small color="pink" @click="del">
                        <v-icon small dark>mdi-delete-outline</v-icon>删除
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table :headers="headers" disable-sort :items="nodeList" :loading="loading" :server-items-length="nodeList.length" hide-default-footer show-select v-model="selected" class="elevation-1">
                      <template v-slot:item.created_at="{ item }">
                        <span>{{$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}}</span>
                      </template>
                      <template v-slot:item.ports="{ item }">
                        <span>{{item.tcp+','+item.udp}}</span>
                      </template>
                      <template v-slot:item.hosts="{ item }">
                        <!-- <li style="list-style: none;display" v-for="(host,index) in item.hosts.split('-')" :key="index" @click="singleInstall(item,host)">
                          <a>{{"["+(index+1)+"]"+host}}</a>
                        </li> -->
                        <li style="list-style: none;display" v-for="(host,index) in item.hosts.split('-')" :key="index" >
                          {{"["+(index+1)+"]"+host}}
                        </li>
                      </template>
                      <template v-slot:item.enabled="{ item }">
                        <v-switch v-model="item.enabled" @change="changeStatus(item)"></v-switch>
                      </template>
                      <template v-slot:item.type="{ item }">
                        <span v-if="item.type==='fnet'">转发网</span>
                        <span v-if="item.type==='lnet'">本地网</span>
                        <span v-if="item.type==='vnet'">虚拟网</span>
                      </template>
                      <template v-slot:item.version="{ item }">
                        <span style="color:red" v-if="item.version==='error'">{{item.version}}</span>
                        <span v-else>{{item.version}}</span>
                      </template>
                      <template v-slot:item.total="{ item }">
                        <span style="color:red" v-if="item.total==='error'">{{item.total}}</span>
                        <span v-else>{{item.total}}</span>
                      </template>
                      <template v-slot:item.option="{ item }">
                        <v-btn class="mx-2" dark small color="cyan" @click="dialogName='编辑节点';edit(item)">
                          <v-icon x-small dark>mdi-pencil</v-icon>编辑
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{dialogName}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea outlined name="input-7-4" label="服务器列表" v-model="addForm.hosts" :rules="[v => !!v || '不能为空']" required></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="addForm.bandwidth" label="带宽(mb)" type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="addForm.tag" label="备注"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false;addForm={tag:'',bandwidth:''}">取消</v-btn>
            <v-btn color="blue darken-1" :disabled="!valid" text @click="save">确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog v-model="exportDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="cyan" dark small @click="exportDialog = false;">取消</v-btn>
            <v-btn color="blue darken-1" class="mx-2" dark small v-clipboard:copy="nodes" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea outlined name="input-7-4" label="节点" v-model="nodes"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog v-model="keyDialog" max-width="800px" scrollable persistent>
        <v-card>
          <v-card-title> 用户名密钥管理</v-card-title>
          <v-card-subtitle>
            已选择{{content}}台服务器，请填写用户名和选择密钥后进行卸载或安装
          </v-card-subtitle>
          <v-card-text>
            <v-text-field v-model="username" label="用户名"></v-text-field>
          </v-card-text>
          <v-card-text>
            <Keylist @keySelect="keySelect" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray darken-1" small @click="keyDialog=false">取消</v-btn>
            <v-btn color="primary" small :disabled="!isSelected" @click="submit('install')">安装</v-btn>
            <v-btn color="error" small :disabled="!isSelected" @click="submit()">卸载</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog v-model="installDialog" max-width="800px" eager scrollable persistent>
        <v-card>
          <v-card-title>
            {{title}}
            <v-spacer></v-spacer>
            <v-btn icon @click="installClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle v-if="wsload">
            数据加载中...
          </v-card-subtitle>
          <v-card-text>
            <Install ref="children" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>

</template>

<script>
  // @ is an alias to /src
  import { post, tips, domain, getCookie } from '@/facade';
  import Keylist from '@/components/KeyList.vue'
  import Install from '@/components/Install.vue'
  export default {
    components: {
      Keylist,
      Install
    },
    data() {
      return {
        gid: "",
        type: 'fnet',
        currentItem: 'tab-0',
        items: [
          { tab: '转发网', value: 'fnet' },
          { tab: '本地网', value: 'lnet' },
          { tab: '虚拟网', value: 'vnet' },
        ],
        loading: true,
        headers: [
          { text: 'ID', value: 'id' },
          { text: '线路', value: 'hosts' },
          { text: '带宽(mb)', value: 'bandwidth' },
          { text: '类型', value: 'type' },
          { text: '状态', value: 'enabled' },
          { text: '备注', value: "tag" },
          { text: '时间', value: 'created_at' },
          { text: '版本', value: "version" },
          { text: '在线人数', value: "total" },
          { text: '延迟(毫秒)', value: "delay" },
          { text: '操作', value: 'option' },
        ],
        tabs: 3,
        form: {},
        dialog: false,
        valid: true,
        addForm: { tag: '', bandwidth: '' },
        selected: [],
        editType: 'add',
        nodeName: "",
        nodes: [],
        exportDialog: false,
        dialogName: "批量添加节点",
        keyDialog: false,
        installDialog: false,
        content: 0,
        isSelected: false,
        title: '一键安装',
        ips: [],
        keyId: "",
        username: "root",
        ipsType: 'server',
        target: '',
        singleModel: false,
        wsload: false
      }
    },
    computed: {
      nodeList() {
        return this.$store.state.nodeList;
      }
    },
    watch: {
      async '$route'(to, from) { // 监听路由是否变化
        if (to.query.id !== from.query.id || to.query.name != from.query.name) {
          this.gid = to.query.id;
          this.nodeName = to.query.name;
          await this.getCount();
          await this.getList();
        }
      }
    },
    async created() {
      if (this.$route.query.id) {
        this.gid = this.$route.query.id;
        this.nodeName = this.$route.query.name;
        await this.getCount();
        await this.getList();
      }
    },
    destroyed() {
      this.$store.commit('changeNodeList', [])
    },
    methods: {
      /**一键安装/卸载 */
      install() {
        if (this.selected.length > 0) {
          this.content = this.selected.length
          this.ips = this.selected.map(item => ({ ip: item.hosts, id: item.id, message: '', icon: '', num: 0 }))
          this.$store.commit('changeIps', this.ips)
          this.keyDialog = true
        } else {
          tips('error', '请先选择节点')
        }
      },
      /**点击单个服务器 */
      singleInstall(item, host) {
        this.keyDialog = true;
        this.content = 1;
        this.ips = [{ ip: host, id: item.id, message: '', icon: '', num: 0 }]
        this.$store.commit('changeIps', this.ips)
        const hosts = item.hosts.split('-');
        const index = hosts.findIndex(item => item == host);
        if (index != hosts.length - 1) {
          this.ipsType = 'forwarld'
          this.target = hosts[index + 1]
        } else {
          this.ipsType = 'server'
        }
      },
      /**选择密钥的子组件把所选密钥传回来 */
      keySelect(value) {
        if (value.length > 0) {
          this.keyId = value[0].id;
          this.isSelected = this.username ? true : false
        } else {
          this.isSelected = false
        }
      },
      /**确定安装或卸载 */
      submit(type = 'uninstall') {
        this.wsload = true;
        this.installDialog = true;
        this.title = type == 'install' ? '一键安装' : '一键卸载';
        this.initWebSocket();
      },
      /**install页面关闭 */
      installClose() {
        this.installDialog = false;
        this.wsload = false;
        this.keyDialog = false
        if (this.websock) {
          this.websock.close()
          this.websock = null;
          this.$store.commit('changeIps', [])
        }
      },
      initWebSocket() { //初始化weosocket
        const start = document.location.protocol == 'https:' ? "wss://" : "ws://"
        const wsuri = start + domain + "/vnet/ws/" + getCookie('uid');
        this.websock = new WebSocket(wsuri);
        this.websock.onopen = this.websocketonopen;
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onerror = this.websocketonerror;
        this.websock.onclose = this.websocketclose;
      },
      websocketonopen() {
        tips('success', 'websocket连接成功')
        this.websocketsend()
      },

      websocketsend() {
        const ipArr = this.ips.map(item => ({ ip: item.ip, id: item.id }))
        let ips = []
        ipArr.forEach((el) => {
          const arr = el.ip.split('-');
          arr.forEach((item, i) => {
            if (arr[i + 1]) {
              ips.push({ host: arr[i], type: 'forwarld', target: arr[i + 1], link_id: el.id })
            } else {
              if (this.ipsType == 'forwarld') ips.push({ host: arr[0], type: 'forwarld', target: this.target, link_id: el.id })
              else ips.push({ host: arr[arr.length - 1], type: 'server', link_id: el.id })
            }
          })
        });
        const info = {
          action: 'SET_SERVER',
          data: { ips: ips, pid: this.keyId, username: this.username, type: this.title == '一键安装' ? 'install' : 'uninstall' }
        }
        this.websock.send(JSON.stringify(info))
      },
      websocketonmessage(e) {
        const resData = JSON.parse(e.data);
        // console.log(resData)
        if (resData.ip) {
          this.wsload = false
          const index = this.ips.findIndex(item => item.id == resData.id);
          if (this.title == '一键安装') {
            if (resData.data.indexOf('forward_status_success') != -1 || resData.data.indexOf('server_status_success') != -1) {
              this.ips[index].num += 1;
              const ipLength = this.ips[index].ip.split('-').length
              if (this.ips[index].num == ipLength) {
                this.ips[index].icon = 'mdi-checkbox-marked-circle'
                this.temper = true
              } else {
                if (!this.temper) this.ips[index].icon = 'mdi-alert-circle'
              }
            }

          } else {
            if (resData.data.indexOf('running server NOW,STOP IT') != -1 || resData.data.indexOf('iptables -t nat -F') != -1) {
              this.ips[index].icon = 'mdi-checkbox-marked-circle'
              this.temper = true
            } else {
              if (!this.temper) this.ips[index].icon = 'mdi-alert-circle'
            }
          }
          // var divscll = document.getElementById('myby' + index)
          var divscll = this.$refs.children.$refs.myby[index]
          // console.log(divscll)
          divscll.scrollTop = divscll.scrollHeight;
          this.ips[index].message += resData.ip + ' -- ' + resData.data + '\n';
          this.$store.commit('changeIps', this.ips)
        }
      },
      websocketonerror(e) {
        console.log(e);
        this.websock = null;
        this.initWebSocket();
      },
      // eslint-disable-next-line no-unused-vars
      websocketclose(e) {
        tips('error', 'ws已断开')
        this.websock = null;
      },
      // eslint-disable-next-line no-unused-vars
      onCopy: function (e) {
        tips('success', '复制成功')
      },
      // eslint-disable-next-line no-unused-vars
      onError: function (e) {
        tips('error', '复制失败')
      },
      /**获取count */
      async getCount() {
        const data = await post('/vnet/group/get', { gid: this.gid })
        if (data.code == 'ok') {
          const count = data.group;
          this.items = [
            { tab: `转发网(${count.fnet_use}/${count.fnet_all})`, value: 'fnet' },
            { tab: `本地网(${count.lnet_use}/${count.lnet_all})`, value: 'lnet' },
            { tab: `虚拟网(${count.vnet_use}/${count.vnet_all})`, value: 'vnet' },
          ]
        }
      },
      /**获取列表 */
      async getList() {
        this.loading = true;
        this.selected = [];
        this.form.gid = this.gid;
        this.form.type = this.type;
        const data = await post('/vnet/link/list', this.form)
        if (data.code == 'ok') {
          this.loading = false;
          this.$store.commit('changeNodeList', data.links)
        } else {
          tips('error', data.message)
        }
      },
      /**tab切换 */
      async tabChange() {
        switch (this.currentItem) {
        case 'tab-0':
          this.type = 'fnet'
          break;
        case 'tab-1':
          this.type = 'lnet'
          break;
        case 'tab-2':
          this.type = 'vnet'
          break;
        default:
        }
        this.$store.commit('changeNodeList', [])
        this.selected = [];
        this.loading = true;
        this.form = {}
        await this.getCount()
        await this.getList();
      },
      /**添加确认节点 */
      async save() {
        const value = this.$refs.form.validate()
        if (value) {
          if (this.editType == 'add') {
            const form = {
              gid: this.gid,
              type: this.type,
              list: this.addForm.hosts,
              tag: this.addForm.tag,
              bandwidth: this.addForm.bandwidth
            }
            const data = await post('/vnet/link/batch', form)
            if (data.code == 'ok') {
              tips('success', '添加成功')
              this.dialog = false;
              this.addForm = { tag: '', bandwidth: '' }
              await this.getList();
              await this.getCount()
            } else {
              tips('error', data.message)
            }
          } else {
            const data = await post('/vnet/link/update', this.addForm)
            if (data.code == 'ok') {
              tips('success', '编辑成功')
              this.dialog = false;
              this.addForm = { tag: '', bandwidth: '' }
              await this.getList();
              await this.getCount()
            } else {
              tips('error', data.message)
            }
          }

        }
      },

      /**删除 */
      async del() {
        if (this.selected.length > 0) {
          const value = await confirm('即将删除，是否继续')
          if (value) {
            const nodel = this.selected.map(item => item.id)
            const form = { gid: this.gid, lids: nodel, type: this.type }
            const data = await post('/vnet/link/delete', form)
            if (data.code == 'ok') {
              tips('success', '删除成功')
              this.getList()
              this.getCount()
            } else {
              tips('error', data.message)
            }
          }
        } else {
          tips('error', '请先选择节点')
        }
      },
      /**探针 */
      async detect() {
        if (this.selected.length > 0) {
          this.loading = true;
          const list = this.selected.map(item => {
            const arr = item.hosts.split('-', 1)[0].split(':');
            const ports = arr[1].split(',')
            const host = { ip: arr[0], tcp: ports[0], udp: ports[1] };
            return host
          })
          const form = {
            hosts: list,
            option: { cmd: 0 },
            timeout: 5000
          }
          const data = await post('/vnet/link/detect', form)
          if (data.code == 'ok') {
            this.loading = false;
            const results = data.results
            for (var i = 0; i < results.length; i++) {
              if (results[i].code == 'ok') {
                this.selected[i].version = results[i].version
                this.selected[i].delay = results[i].delay
              } else {
                this.selected[i].version = 'error'
                this.selected[i].delay = results[i].delay
              }
            }
            let nodeList = this.$store.state.nodeList
            for (var n = 0; n < nodeList.length; n++) {
              for (var j = 0; j < this.selected.length; j++) {
                if (nodeList[n].id == this.selected[j].id) {
                  nodeList[n].version = this.selected[j].version
                  nodeList[n].delay = this.selected[j].delay
                }
              }
            }
            this.$store.commit('changeNodeList', [])
            this.$store.commit('changeNodeList', nodeList)
          } else {
            tips('error', data.message)
          }
        } else {
          tips('error', '请先选择节点')
        }
      },
      /**在线人数 */
      async online() {
        if (this.selected.length > 0) {
          this.loading = true;
          const list = this.selected.map(item => {
            const arr = item.hosts.split('-', 1)[0].split(':');
            const ports = arr[1].split(',')
            const host = { ip: arr[0], tcp: ports[0], udp: ports[1] };
            return host
          })
          const form = {
            hosts: list,
            option: { cmd: 9 },
            timeout: 5000
          }
          const data = await post('/vnet/link/detect', form)
          if (data.code == 'ok') {
            this.loading = false;
            const results = data.results
            for (var i = 0; i < results.length; i++) {
              if (results[i].code == 'ok') {
                this.selected[i].total = results[i].total
                this.selected[i].delay = results[i].delay
              } else {
                this.selected[i].total = 'error'
                this.selected[i].delay = results[i].delay
              }
            }
            let nodeList = this.$store.state.nodeList
            for (var n = 0; n < nodeList.length; n++) {
              for (var j = 0; j < this.selected.length; j++) {
                if (nodeList[n].id == this.selected[j].id) {
                  nodeList[n].total = this.selected[j].total
                  nodeList[n].delay = this.selected[j].delay

                }
              }
            }
            this.$store.commit('changeNodeList', [])
            this.$store.commit('changeNodeList', nodeList)
          } else {
            tips('error', data.message)
          }
        } else {
          tips('error', '请先选择节点')
        }
      },
      /**改变节点状态 */
      async changeStatus(item) {
        const form = { lid: item.id, enabled: !!item.enabled, host: item.host, tag: item.tag }
        const data = await post('/vnet/link/update', form)
        if (data.code == 'ok') {
          tips('success', '编辑成功')
          await this.getList()
          await this.getCount()
        } else {
          tips('error', data.message)
          await this.getList()
          await this.getCount()
        }
      },
      /**编辑按钮 */
      edit(item) {

        this.addForm.hosts = item.hosts;
        this.addForm.lid = item.id;
        this.addForm.tag = item.tag;
        this.addForm.bandwidth = item.bandwidth;
        this.addForm.enabled = !!item.enabled;
        this.editType = 'edit';
        this.dialog = true;
      },
      /**返回 */
      back() {
        this.$router.back({ path: '/nodeGroup', query: { id: localStorage.getItem('netId') } })
      },
      /**批量更改状态 */
      async statusChange(param) {
        if (this.selected.length > 0) {
          const lids = this.selected.map(item => item.id)
          const form = {
            gid: this.gid,
            type: this.type,
            enabled: param,
            lids: lids
          }
          const data = await post('/vnet/link/status', form)
          if (data.code == 'ok') {
            tips('success', '更改成功')
            this.getList()
            this.getCount()
          } else {
            tips('error', data.message)
          }
        } else {
          tips('error', '请先选择节点')
        }
      },
      /**导出 */
      exportNode() {
        if (this.selected.length > 0) {
          this.nodes = this.selected.map(item => item.hosts).join("\n")
          this.exportDialog = true;
        } else {
          tips('error', '请先选择节点')
        }
      },


    },
  }
</script>

<style lang="scss" scoped>
  .code-mirror-div {
    padding: 2px;
    height: 500px;

    .tool-bar {
      top: 20px;
      margin: 30px 2px 0px 20px;
    }

  }
</style>