<template>
  <v-tabs dark background-color="primary" show-arrows slider-size>
    <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

    <v-tab v-for="(item,index) in ips" :key="index" :href="'#tab-' + index" >
      <v-icon small left>{{item.icon}}</v-icon>{{item.ip}}
    </v-tab>
    <v-tab-item v-for="(item,index) in ips" :key="index" :value="'tab-' + index" eager>
      <div ref="myby" :id="'myby'+index" style="height:450px;background-color:#000000;color:#ffffff;overflow:auto;padding:10px;white-space: pre;">
        {{item.message}}
      </div>
    </v-tab-item>
  </v-tabs>
</template>
<script>
  export default {
    name: 'install',
    data() {
      return {
        title: '一键安装'
      }
    },
    computed: {
      ips() {
        return this.$store.state.ips;
      }
    }
  }
</script>